import { format as dateFnsFormat } from 'date-fns';
import polishLocale from 'date-fns/locale/pl';
import { APPLICATION_STATUS, APPLICATION_STATUS_SYMBOL } from './variables';
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const polishPlural = (
  singularNominativ,
  pluralNominativ,
  pluralGenitive,
  value
) => {
  if (value === 1) {
    return singularNominativ;
  } else if (
    value % 10 >= 2 &&
    value % 10 <= 4 &&
    (value % 100 < 10 || value % 100 >= 20)
  ) {
    return pluralNominativ;
  } else {
    return pluralGenitive;
  }
};

export const fileToBase64 = async (file) => {
  const fr = new FileReader();
  return new Promise((resolve, reject) => {
    try {
      fr.onload = function () {
        const base64 = fr.result;
        const parsedBase64 = base64.substr(base64.indexOf(',') + 1);
        resolve(parsedBase64);
      };
      fr.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
};

export const parseObjectToUrlQuery = (object) =>
  Object.entries(object)
    .map((a) => a.join('='))
    .join('&');

export const parseCurrency = (val) => {
  if (!val) return 0.0;
  return parseFloat(val.replace(' ', '').replace(',', '.'));
};

export const parsePointToComa = (val) => {
  if (!val) return '0';
  return val.toString().replace('.', ',');
};

const formatter = Intl.NumberFormat('pl-PL', {
  style: 'currency',
  currency: 'PLN',
  minimumFractionDigits: 2,
  // currencySign: "accounting",
});
export const formatCurrency = (value) => {
  return formatter.format(value);
};

export const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
};

export const dateFormat = (date, _format) =>
  dateFnsFormat(date, _format, { locale: polishLocale });

export const parseBytes = (size, prefix) => {
  if (size > 999999) {
    return `${Math.round(size / 100000) / 10}${prefix && ' MB'}`;
  } else if (size > 999) {
    return `${parseInt(size / 1000)}${prefix && ' KB'}`;
  } else {
    return `${size}${prefix && ' B'}`;
  }
};

export const debounce = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const checkIsDisabledScoring = (applicationStatus) => {
  return (
    applicationStatus === APPLICATION_STATUS_SYMBOL.WAITING_FOR_AGREEMENT ||
    applicationStatus === APPLICATION_STATUS_SYMBOL.CONFIRM_AGREEMENT ||
    applicationStatus === APPLICATION_STATUS_SYMBOL.COMPLETED ||
    applicationStatus === APPLICATION_STATUS_SYMBOL.REJECTED ||
    applicationStatus === APPLICATION_STATUS_SYMBOL.ARCHIVED
  );
};

export const getApplicationStatusBadgeColor = (status_text) => {
  switch (status_text) {
    case APPLICATION_STATUS.WAITING_TO_BE_TAKEN:
      return 'danger';
    case APPLICATION_STATUS.WAITING_FOR_RESERVATION:
      return 'danger';
    case APPLICATION_STATUS.WAITING_FOR_DECISION:
      return 'danger';
    case APPLICATION_STATUS.WAITING_FOR_AGREEMENT:
      return 'success';
    case APPLICATION_STATUS.CONFIRM_AGREEMENT:
      return 'danger';
    case APPLICATION_STATUS.REJECTED:
      return 'danger';
    case APPLICATION_STATUS.COMPLETED:
      return 'success';
    case APPLICATION_STATUS.BIK_ERROR:
      return 'danger';
    case APPLICATION_STATUS.ARCHIVED:
      return 'dark';
    default:
      return 'primary';
  }
};

export const convertError = (invalid_property, message) => {
  switch (invalid_property) {
    case 'name':
      return `Nazwa: ${message}`;
    case 'brand':
      return `Marka: ${message}`;
    case 'discount':
      return `Rabat: ${message}`;
    case 'category':
      return `Kategoria: ${message}`;
    case 'availability':
      return `Dostępność: ${message}`;
    case 'productCondition':
      return `Stan: ${message}`;
    case 'image_url':
      return `Zdjęcia: ${message}`;
    case 'gross_price':
      return `Cena cennikowa brutto: ${message}`;
    case 'net_price':
      return `Cena cennikowa netto: ${message}`;
    case 'url':
      return `Adres URL produktu w sklepie partnerskim: ${message}`;
    case 'product_code':
      return `Kod produktu: ${message}`;
    case 'intervals':
      return `Czas pakietu w miesiącach: ${message}`;
    case 'feedUpdate':
      return message;
    default:
      return message;
  }
};

export const mapInvalidProperty = (invalidProperty) => {
  switch (invalidProperty) {
    case 'productCondition':
      return 'condition_symbol';
    case 'availability':
      return 'availability_symbol';
    case 'gross_price':
      return 'customer_gross_price';
    case 'net_price':
      return 'customer_net_price';
    case 'intervals':
      return 'oneInterval';
    default:
      return invalidProperty;
  }
};

export const checkAccess = (permissions = [], role) => {
  return permissions.some((element) => element === role);
};

export const convertTakNieToBoolean = (word) =>
  ({
    TAK: true,
    NIE: false,
  }[word]);
