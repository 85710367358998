import React, {useEffect} from 'react'
import {
    CSidebarNavItem,
} from '@coreui/react';
import {useQuery} from "react-query";
import RepurchaseService from "../../services/RepurchaseService";
import { useLocation } from 'react-router-dom';

const SidebarMenuCounter = ({name, to, icon, badge}) => {
    const location = useLocation();
    const {data, isSuccess, refetch} = useQuery('counter', RepurchaseService.getRepurchaseCounter())

    useEffect(() => {
        refetch()
    }, [location, refetch]);

    return (isSuccess &&
        <CSidebarNavItem name={name} to={to} badge={data.count > 0 && {...badge, text: data.count}} icon={icon}/>
    )}

export default SidebarMenuCounter
